import { Prisma } from ".prisma/client";
import { message, Space, Spin } from "antd";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { joinPath } from "../../../common/utility";
import Center from "../../components/center";
import CompleteBtn from "../../components/complete-btn";
import DeleteBtn from "../../components/delete-btn";
import LaydownAreaList from "../../components/laydown-area-list";
import ProjectForm from "../../components/project-form";
import RoadClosureList from "../../components/road-clousure-list";
import useUser from "../../hooks/use-user";
import httpClient from "../../libs/http-client";

interface ProjectUpdatePageProps { }

type Project = Prisma.ProjectGetPayload<{
  include: {
    area: true;
  }
}>

const ProjectUpdatePage: FunctionComponent<ProjectUpdatePageProps> = () => {
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState<Partial<Project> | undefined>(
    undefined
  );
  const [saving, setSaving] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const { adminOf } = useUser();

  const onFinish = useCallback(
    async (values: any) => {
      setSaving(true);
      try {
        if (!adminOf(project?.area_id)) {
          throw new Error("You are not authorized to update this project!");
        }
        const response = await httpClient.put<Project>(
          `/projects/${id}`,
          values
        );
        if (response.success && response.data) {
          message.success("Project updated successfully!");
          setProject(project => ({
            ...project,
            ...response.data
          }));
        } else {
          throw new Error(response.message);
        }
      } catch (error: any) {
        message.error(error.message ?? "Failed to update project for unknown reason!");
      }
      setSaving(false);
    },
    [id, adminOf]
  );

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    httpClient
      .get<Project>(`/projects/${id}`)
      .then((response) => {
        if (response.success && response.data) {
          mounted && setProject(response.data);
        } else {
          throw new Error(response.message);
        }
      })
      .catch((error: any) => {
        message.error("Project not found!");
        navigate("/projects");
      })
      .finally(() => {
        mounted && setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [id]);

  if (loading) {
    return (
      <Center>
        <Spin />
      </Center>
    );
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <ProjectForm
        title="Update Project"
        additionalButtons={adminOf(project?.area_id) ? [
          <DeleteBtn
            key="delete"
            apiURL={`/projects/${id}`}
            onDelete={() => navigate("/projects")}
          />,
          <CompleteBtn
            key="complete"
            baseUrl={joinPath("/projects", id!, "complete")}
            isComplete={!!project?.is_complete}
            onUpdate={(data) => {
              setProject(project => ({
                ...project,
                ...data
              }));
            }}
            size="large"
          />
        ] : []}
        onChange={onFinish}
        busy={saving}
        value={project}
      />
      {
        project && <RoadClosureList project={project as Project} />
      }
      {
        project && <LaydownAreaList project={project as Project} />
      }
    </Space>
  );
};

export default ProjectUpdatePage;
