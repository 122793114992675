import { Project, ProjectArea } from ".prisma/client";
import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  Space,
  Spin,
  Typography
} from "antd";
import { FunctionComponent } from "react";
import useUser from "../hooks/use-user";
import AsyncSelect from "./async-select";
import Editor from "./canvas/editor";
import ObjectType from "./canvas/object-type";
import InputDate from "./input-date";

interface ProjectFormProps {
  value?: Partial<Project>;
  onChange?: (value: Partial<Project>) => void;
  busy?: boolean;
  error?: string;
  title: string;
  additionalButtons?: React.ReactNode;
}

const ProjectForm: FunctionComponent<ProjectFormProps> = ({
  value,
  onChange,
  busy,
  error,
  title,
  additionalButtons,
}) => {
  const [form] = Form.useForm();
  const area: ProjectArea = Form.useWatch("area", form);
  const { adminOf } = useUser();
  const triggerChange = (changedValue: any) => {
    if (changedValue?.area?.id) {
      changedValue.area_id = changedValue?.area?.id;
    }
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };
  return (
    <Form
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      onFinish={triggerChange}
      initialValues={value}
      colon={false}
    >
      <Form.Item label=" ">
        <Typography.Title level={3}>{title}</Typography.Title>
      </Form.Item>
      {error && (
        <Form.Item label=" ">
          <Alert type="error" message={error} />
        </Form.Item>
      )}
      <Form.Item
        label="Project Area"
        name="area"
        rules={[{ required: true, message: "Please select a project area" }]}
      >
        <AsyncSelect
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          url={`/project-areas?with-admin-access=true`}
          style={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
            } as any),
          }}
        />
      </Form.Item>
      <Form.Item
        label="Project Name"
        name="name"
        rules={[{ required: true, message: "Please enter project name" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Id Number"
        name="id_number"
      >
        <Input />
      </Form.Item>
      <Form.Item label="Project Number" name="pid">
        <Input />
      </Form.Item>


      <Form.Item
        label="Project Location"
        name="shape"
        rules={[
          {
            required: true,
            message: "Please identify project location from map",
          },
        ]}
      >
        <Editor
          height={area?.map_height}
          width={area?.map_width}
          src={area?.project_map}
          objectType={ObjectType.Project}
          objectId={value?.id}

        />
      </Form.Item>

      <Form.Item label="SCIP ID" name="scip_id">
        <Input />
      </Form.Item>
      <Form.Item label="By" name="by">
        <Input />
      </Form.Item>

      <Form.Item label="CO" name="co">
        <Input />
      </Form.Item>

      <Form.Item label="COR" name="cor">
        <Input />
      </Form.Item>

      <Form.Item label="Manager" name="manager">
        <Input />
      </Form.Item>

      <Form.Item label="Superintendent" name="superintendent">
        <Input />
      </Form.Item>

      <Form.Item label="Contractor" name="contractor">
        <Input />
      </Form.Item>
      <Form.Item label="Contract Amount" name="contract_amount">
        <InputNumber
          prefix="$"
          style={{ width: "100%" }}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value?.replace(/\$\s?|(,*)/g, "") || ""}
        />
      </Form.Item>

      <Form.Item label="AE" name="ae">
        <Input />
      </Form.Item>

      <Form.Item label="Procedure" name="procedure">
        <Input />
      </Form.Item>
      <Form.Item label="Award Date" name="award_date">
        <InputDate />
      </Form.Item>
      <Form.Item label="Design Start Date" name="design_start_date">
        <InputDate />
      </Form.Item>

      <Form.Item label="Design End Date" name="design_end_date">
        <InputDate />
      </Form.Item>

      <Form.Item label="Construction Start Date" name="construction_start_date">
        <InputDate />
      </Form.Item>

      <Form.Item label="Construction End Date" name="construction_end_date">
        <InputDate />
      </Form.Item>

      <Form.Item label=" ">
        <Space>
          {
            adminOf(value?.area_id) && <Spin spinning={busy}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Spin>
          }
          {additionalButtons}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ProjectForm;
