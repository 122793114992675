import { parseRateLimit } from 'ratelimit-header-parser';
import dayjs from "../../common/dayjs";
import { joinPath } from "../../common/utility";
import { ResponseObject } from "../types/response-object";
import { getAccessToken, getRefreshToken, refreshTokens } from "./token-manager";

const host = process.env.API_HOST as string;
const root = joinPath(host, "api");
const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
class HttpClient {
  constructor() { }
  get isOffline() {
    return !navigator.onLine;
  }
  prepareUrl(url: string) {
    // add root url if not present
    if (!url.startsWith(root)) {
      url = joinPath(root, url);
    }
    return url;
  }

  async getHeaders(url: string) {
    let access_token = getAccessToken();
    let refresh_token = getRefreshToken();
    if (!access_token && refresh_token) {
      const isRefreshUrl = url.includes("/auth/refresh");
      if (!isRefreshUrl) { // don't refresh token if already on refresh url to avoid infinite loop
        access_token = await refreshTokens();
      }
    }
    const header = new Headers();
    header.append("Content-Type", "application/json");
    header.append("Accept", "application/json");
    if (access_token?.token) {
      header.append("Authorization", `Bearer ${access_token.token}`);
    }
    return header;
  }

  private sendRequest = async <T = any>(
    url: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    body?: any,
  ): Promise<ResponseObject<T>> => {
    if (this.isOffline) return Promise.reject(new Error(
      "You are offline. Please check your internet connection."
    ));
    url = this.prepareUrl(url);
    const headers = await this.getHeaders(url);

    if (!(body instanceof FormData)) {
      body = JSON.stringify(body);
    }

    // delete headers["Content-Type"] if body is form data
    if (body instanceof FormData) {
      headers.delete("Content-Type");
    }
    try {
      const response = await fetch(url, {
        method,
        headers,
        body,
      });
      if (response.status === 429) {
        console.log('Rate limit exceeded');
        // handle rate limit
        const rateLimit = parseRateLimit(response);
        if (rateLimit) {
          const { reset } = rateLimit;
          const waitTime = reset ? dayjs.utc(reset).diff(dayjs.utc(), 'milliseconds') : 60 * 1000;
          console.log('Waiting for ', waitTime);
          await wait(waitTime);
          return this.sendRequest<T>(url, method, body) as Promise<ResponseObject<T>>;
        }
      }
      // make sure we have some data before calling response.json()
      const text = await response.text();
      if (!text) {
        return { success: response.ok, status: response.status, message: response.statusText } as ResponseObject<T>;
      }
      return JSON.parse(text) as ResponseObject<T>;
    } catch (error) {
      console.log('Error: ', error);
      throw error;
    }
  };


  async get<T = any>(url: string) {
    try {
      return await this.sendRequest<T>(url, "GET");
    } catch (error) {
      throw error;
    }
  }

  async post<T = any>(url: string, body?: any) {
    try {
      return await this.sendRequest<T>(url, "POST", body);
    } catch (error) {
      throw error;
    }
  }

  async put<T = any>(url: string, body?: any) {
    try {
      return await this.sendRequest<T>(url, "PUT", body);
    } catch (error) {
      throw error;
    }
  }

  async delete<T = any>(url: string, data?: any) {
    try {
      return await this.sendRequest<T>(url, "DELETE", data);
    } catch (error) {
      throw error;
    }
  }
}

export default new HttpClient();
