import { ProjectArea } from ".prisma/client";
import { ColumnType } from "antd/es/table";
import { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import replaceParams from "../../../common/replace-params";
import { formatDate, joinPath } from "../../../common/utility";
import DeleteBtn from "../../components/delete-btn";
import InfiniteTable from "../../components/infinite-table";
import usePaginator from "../../hooks/use-paginator";
import useUser from "../../hooks/use-user";
import { paginator } from "../../libs/paginator";

interface ProjectAreaListPageProps { }

const ProjectAreaListPage: FunctionComponent<ProjectAreaListPageProps> = () => {
  const { load, loading, data, done, reset, updateData } =
    usePaginator<ProjectArea>();

  const { isSuperAdmin } = useUser();

  const reload = useCallback((query?: string) => {
    const request = paginator<ProjectArea>(
      "project-areas",
      { query } as any,
      50
    );
    reset(request);
  }, []);

  useEffect(() => {
    reload();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        key: "name",
        title: "Name",
        render(value, row, index) {
          return (
            <Link to={replaceParams("/project-areas/:id", { id: row.id })}>
              {row.name}
            </Link>
          );
        },
      },
      {
        key: "project_map",
        title: "Map",
        render(value, row, index) {
          return <a href={row.project_map}>{row.project_map}</a>;
        },
      },
      {
        key: "created_at",
        title: "Created At",
        render(value, row) {
          return formatDate(row.created_at, "LLL");
        },
      },
      {
        key: "updated_at",
        title: "Updated At",
        render(value, row) {
          return formatDate(row.updated_at, "LLL");
        },
      },
      {
        key: "actions",
        title: "#",
        render(value, row) {
          return isSuperAdmin && <DeleteBtn
            key="delete"
            apiURL={joinPath("/project-areas", row.id)}
            onDelete={() => {
              updateData((list) => list.filter((item) => item.id !== row.id));
            }}
          />
        },
      },
    ] as ColumnType<ProjectArea>[];
  }, []);
  return (
    <InfiniteTable
      loading={loading}
      columns={columns}
      hasMore={!done}
      loadMore={load}
      id={"id"}
      key={"id"}
      rowKey={"id"}
      pagination={false}
      dataSource={data}
      title="Project Areas"
      onSearch={reload}
    />
  );
};

export default ProjectAreaListPage;
