import { Prisma } from ".prisma/client";
import { Space } from "antd";
import { ColumnType } from "antd/es/table";
import { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import replaceParams from "../../../common/replace-params";
import { formatDate, joinPath } from "../../../common/utility";
import CompleteBtn from "../../components/complete-btn";
import DeleteBtn from "../../components/delete-btn";
import InfiniteTable from "../../components/infinite-table";
import ToggleSwitch from "../../components/toggle-switch";
import usePaginator from "../../hooks/use-paginator";
import useUser from "../../hooks/use-user";
import { paginator } from "../../libs/paginator";

interface ProjectListPageProps { }

type Project = Prisma.ProjectGetPayload<{
  include: {
    area: true;
  };
}>;

const ProjectListPage: FunctionComponent<ProjectListPageProps> = () => {
  const { load, loading, data, done, reset, updateData } =
    usePaginator<Project>();

  const reload = useCallback((query?: string) => {
    const request = paginator<Project>("projects", { query } as any, 50);
    reset(request);
  }, []);

  const { adminOf } = useUser();

  useEffect(() => {
    reload();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        key: "name",
        title: "Name",
        render(value, row, index) {
          return (
            <Link to={replaceParams("/projects/:id", { id: row.id })}>
              {row.name}
            </Link>
          );
        },
      },
      {
        key: "area",
        title: "Project Area",
        render(value, row, index) {
          return row.area?.name;
        },
      },
      {
        key: "pid",
        title: "PID",
        render(value, row, index) {
          return row.pid;
        },
      },
      {
        key: "scip_id",
        title: "Scip ID",
        render(value, row, index) {
          return row.scip_id;
        },
      },
      {
        key: "is_complete",
        title: "Complete",
        render(value, row, index) {
          if (!adminOf(row.area_id)) {
            return row.is_complete ? "Yes" : "No";
          }
          return <ToggleSwitch
            checkedLabel="Yes"
            uncheckedLabel="No"
            isChecked={row.is_complete}
            successMessage={"Project is marked as " + (row.is_complete ? "incomplete" : "complete")}
            errorMessage={"Failed to mark project as " + (row.is_complete ? "incomplete" : "complete")}
            putUrl={checked => joinPath("/projects", row.id, "complete", checked ? "1" : "0")}
            onChange={(checked) => {
              updateData((list) => list.map((item) => {
                if (item.id === row.id) {
                  return {
                    ...item,
                    is_complete: checked
                  }
                }
                return item;
              }));
            }}
          />
        },
      },
      {
        key: "created_at",
        title: "Created At",
        render(value, row) {
          return formatDate(row.created_at, "LLL");
        },
      },
      {
        key: "updated_at",
        title: "Updated At",
        render(value, row) {
          return formatDate(row.updated_at, "LLL");
        },
      },
      {
        key: "actions",
        title: "#",
        render(value, row) {
          return adminOf(row.area_id) && <Space>
            <DeleteBtn
              key="delete"
              apiURL={joinPath("/projects", row.id)}
              onDelete={() => {
                updateData((list) => list.filter((item) => item.id !== row.id));
              }}
            />

            <CompleteBtn
              key="complete"
              baseUrl={joinPath("/projects", row.id, "complete")}
              isComplete={row.is_complete}
              onUpdate={(data) => {
                updateData((list) => list.map((item) => {
                  if (item.id === row.id) {
                    return {
                      ...item,
                      ...data
                    }
                  }
                  return item;
                }));
              }}
            />

          </Space>
        },
      },
    ] as ColumnType<Project>[];
  }, [adminOf]);
  return (
    <InfiniteTable
      loading={loading}
      columns={columns}
      hasMore={!done}
      loadMore={load}
      id={"id"}
      key={"id"}
      rowKey={"id"}
      pagination={false}
      dataSource={data}
      title="Project Areas"
      onSearch={reload}
    />
  );
};

export default ProjectListPage;
