import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import { Button, message, Popconfirm, Spin } from "antd";
import { FunctionComponent, useCallback, useState } from "react";
import httpClient from "../libs/http-client";

interface DeleteBtnProps {
  apiURL: string;
  onDelete: () => void;
}

const DeleteBtn: FunctionComponent<DeleteBtnProps> = ({ apiURL, onDelete }) => {
  const [deleting, setDeleting] = useState(false);

  const deleteItem = useCallback(async () => {
    setDeleting(true);
    try {
      const response = await httpClient.delete(apiURL);
      if (response.success) {
        message.success("Deleted successfully");
        onDelete();
      } else {
        throw new Error(response.message);
      }
    } catch (e: any) {
      message.error(e.message || "Something went wrong");
    }
    setDeleting(false);
  }, [apiURL, onDelete]);

  return (
    <Popconfirm
      title="Are you sure to delete this item?"
      okText="Yes"
      cancelText="Cancel"
      onConfirm={deleteItem}
    >
      <Spin spinning={deleting}>
        <Button
          danger
          type={"primary"}
          htmlType="button"
          icon={<DeleteOutlined />}
        />
      </Spin>
    </Popconfirm>
  );
};

export default DeleteBtn;
