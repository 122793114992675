import { type EmailTemplate } from ".prisma/client";
import { Space, Spin, message } from "antd";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Center from "../../components/center";
import DeleteBtn from "../../components/delete-btn";
import EmailTemplateForm from "../../components/email-template-form";
import { updateEmailTemplateCache } from "../../libs/get-email-templates";
import httpClient from "../../libs/http-client";

interface EmailTemplateUpdatePageProps { }

const EmailTemplateUpdatePage: FunctionComponent<EmailTemplateUpdatePageProps> = () => {
  const [loading, setLoading] = useState(true);
  const [EmailTemplate, setEmailTemplate] = useState<Partial<EmailTemplate> | undefined>(undefined);
  const [saving, setSaving] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const onFinish = useCallback(
    async (values: any) => {
      setSaving(true);
      try {
        const response = await httpClient.put<EmailTemplate>(`/email-templates/${id}`, values);
        if (response.success && response.data) {
          updateEmailTemplateCache(response.data);
          message.success("Email template updated successfully!");
          setEmailTemplate(response.data);
        } else {
          throw new Error(response.message);
        }
      } catch (error: any) {
        message.error(error.message ?? "Failed to update for unknown reason!");
      }
      setSaving(false);
    },
    [id]
  );

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    httpClient
      .get<EmailTemplate>(`/email-templates/${id}`)
      .then((response) => {
        if (response.success && response.data) {
          updateEmailTemplateCache(response.data);
          mounted && setEmailTemplate(response.data);
        } else {
          throw new Error(response.message);
        }
      })
      .catch((error: any) => {
        message.error("Failed to load email template!");
        navigate("/email-templates");
      })
      .finally(() => {
        mounted && setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [id]);

  if (loading) {
    return (
      <Center>
        <Spin />
      </Center>
    );
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <EmailTemplateForm
        title="Update Email Template"
        additionalButtons={[
          <DeleteBtn
            key="delete"
            apiURL={`/email-templates/${id}`}
            onDelete={() => navigate("/email-templates")}
          />,
        ]}
        onChange={onFinish}
        busy={saving}
        value={EmailTemplate}
      />
    </Space>
  );
};

export default EmailTemplateUpdatePage;
