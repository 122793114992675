import { AppConfig } from ".prisma/client";
import EditOutlined from "@ant-design/icons/EditOutlined";
import { Button, Form, Input, message, Modal, Spin } from "antd";
import { ColumnType } from "antd/es/table";
import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { joinPath } from "../../common/utility";
import AppContext from "../context/app-context";
import usePaginator from "../hooks/use-paginator";
import httpClient from "../libs/http-client";
import { paginator } from "../libs/paginator";
import InfiniteTable from "./infinite-table";

interface AppConfigListProps { }

const AppConfigList: FunctionComponent<AppConfigListProps> = () => {
  const { updateConfigs } = useContext(AppContext);
  const [model, setModel] = useState<AppConfig>();
  const [saving, setSaving] = useState(false);

  const { load, loading, data, done, reset, updateData } =
    usePaginator<AppConfig>();

  const save = useCallback(
    async (data: any) => {
      setSaving(true);
      try {
        const url = joinPath("/app-configs", model!.id);
        const response = await httpClient.put<AppConfig>(url, data);
        if (response.success && response.data) {
          message.success("Config saved successfully!");
          setModel(undefined);
          updateData((list) =>
            list.map((item) =>
              item.id === response?.data?.id ? (response.data as any) : item
            )
          );
        }
      } catch (error: any) {
        message.error(error.message ?? "Failed to update app config for unknown reason!");
      }
      setSaving(false);
    },
    [model, updateData]
  );

  const reload = useCallback(
    (query?: string) => {
      const request = paginator<AppConfig>(
        "app-configs",
        { query } as any,
        50
      );
      reset(request);
    },
    []
  );

  useEffect(() => {
    reload();
  }, [reload]);

  useEffect(() => {
    updateConfigs(data);
  }, [data]);

  const columns = useMemo(() => {
    return [
      {
        key: "description",
        title: "Name",
        render(value, row, index) {
          return row.description;
        },
      },
      {
        key: "value",
        title: "Value",
        render(value, row, index) {
          return row.value;
        },
      },
      {
        key: "actions",
        title: "#",
        render(value, row) {
          return <Button
            key="edit"
            type="primary"
            onClick={() => {
              setModel(row);
            }}
            icon={<EditOutlined />}
          />
        },
      },
    ] as ColumnType<AppConfig>[];
  }, []);
  return (
    <>
      <InfiniteTable
        loading={loading}
        columns={columns}
        hasMore={!done}
        loadMore={load}
        id={"id"}
        key={"id"}
        rowKey={"id"}
        pagination={false}
        dataSource={data}
        title="App Configs"
      />
      <Modal
        title={model?.description ?? "Update Config"}
        open={!!model}
        onCancel={() => setModel(undefined)}
        onOk={save}
        footer={null}
        destroyOnClose
      >
        {model && (
          <Form layout="vertical" initialValues={model} onFinish={save}>
            <Form.Item>
              <Form.Item
                name="value"
                label="Value"
                rules={[{ required: true, message: "Value is required" }]}
              >
                <Input type={'color'} />
              </Form.Item>
              <Form.Item label=" ">
                <Spin spinning={saving}>
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Spin>
              </Form.Item>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default AppConfigList;
