import { Prisma } from ".prisma/client";
import { Alert, Button, Col, Flex, Form, Input, message, Row, Spin, Typography } from "antd";
import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Center from "../../components/center";
import httpClient from "../../libs/http-client";

interface ProfilePageProps { }

type User = Prisma.UserGetPayload<{
  include: {
    groups: true;
    admin_of: true;
  }
}>

type ProfileRowProps = {
  label: ReactNode;
  children: ReactNode;
}

const ProfileRow: FunctionComponent<ProfileRowProps> = ({ label, children }) => {
  return <Row style={{ margin: '5px 0' }}>
    <Col span={4}>
      <Typography.Text strong>{label}</Typography.Text>
    </Col>
    <Col span={20}>
      {children}
    </Col>
  </Row>
}

const ProfilePage: FunctionComponent<ProfilePageProps> = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<Partial<User> | undefined>(undefined);
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFormSubmit = (values: any) => {
    if (values.password !== values.confirm_password) {
      return message.error("Password and confirm password does not match!");
    }

    setSaving(true);

    httpClient
      .post(`/auth/update-password`, values)
      .then((response) => {
        if (response.success) {
          message.success("Profile updated successfully!");
          form.resetFields();

        } else {
          throw new Error(response.message);
        }
      })
      .catch((error: any) => {
        message.error(error.message ?? "Failed to update for unknown reason!");
      })
      .finally(() => {
        setSaving(false);
      });
  }

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    httpClient
      .get<User>(`/users/profile`)
      .then((response) => {
        if (response.success && response.data) {
          mounted && setUser(response.data);
        } else {
          throw new Error(response.message);
        }
      })
      .catch((error: any) => {
        message.error(error.message ?? "Failed to update for unknown reason!");

        navigate({
          pathname: "/error",
          search: `?message=${error.message || "Something went wrong!"}`,
        });
      })
      .finally(() => {
        mounted && setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, []);

  if (loading) {
    return (
      <Center>
        <Spin />
      </Center>
    );
  }

  return <Flex vertical>
    <Typography.Title level={3}>Profile</Typography.Title>
    <ProfileRow label="Name">{user?.first_name} {user?.last_name}</ProfileRow>
    <ProfileRow label="Email">{user?.email}</ProfileRow>
    <ProfileRow label="Groups">
      {!user?.groups?.length && "None"}
      {
        !!user?.groups?.length && <ul>
          {user.groups.map((group) => <li key={group.id}>
            <Link to={`/user-groups/${group.id}`}>{group.name}</Link>
          </li>)}
        </ul>
      }
    </ProfileRow>
    <ProfileRow label="Admin of">
      {!user?.admin_of?.length && "None"}
      {
        !!user?.admin_of?.length && <ul>
          {user.admin_of.map((each) => <li key={each.id}>
            <Link to={`/project-areas/${each.id}`}>{each.name}</Link>
          </li>)}
        </ul>
      }
    </ProfileRow>
    <Alert type="info" message="Contact administrator to update your profile" />
    <Typography.Title level={4}>Change Password</Typography.Title>
    <Form
      wrapperCol={{ span: 12 }}
      labelCol={{ span: 6 }}
      labelAlign="left"
      form={form}
      onFinish={onFormSubmit}
    >
      <Form.Item name={'old_password'} label="Old Password" rules={[{ required: true, message: "Old password is required" }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item name={'password'} label="New Password" rules={[{ required: true, message: "New password is required" }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item name={'confirm_password'} label="Confirm Password" rules={[{ required: true, message: "Confirm password is required" }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={saving}>Update Password</Button>
      </Form.Item>
    </Form>
  </Flex>
};

export default ProfilePage;
