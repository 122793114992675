import LockOutlined from "@ant-design/icons/LockOutlined";
import UnlockOutlined from "@ant-design/icons/UnlockOutlined";
import { Button, message, Popconfirm, Spin } from "antd";
import { FunctionComponent, useCallback, useState } from "react";
import { joinPath } from "../../common/utility";
import httpClient from "../libs/http-client";


interface CompleteBtnProps {
  baseUrl: string;
  isComplete: boolean;
  onUpdate: (data: any) => void;
  size?: "small" | "large";
}

const CompleteBtn: FunctionComponent<CompleteBtnProps> = ({ baseUrl, isComplete, onUpdate, size = 'small' }) => {
  const [updating, setUpdating] = useState(false);

  const updateItem = useCallback(async () => {
    setUpdating(true);
    try {
      const response = await httpClient.put<any>(
        joinPath(baseUrl, isComplete ? 'false' : 'true')
      );
      if (response.success) {
        message.success(
          isComplete ? "Marked as incomplete successfully" : "Marked as complete successfully"
        );
        onUpdate(response.data);
      } else {
        throw new Error(response.message);
      }
    } catch (e: any) {
      message.error(e.message || "Something went wrong");
    }
    setUpdating(false);
  }, [baseUrl, isComplete, onUpdate]);

  const text = size === 'large' ? isComplete ? "Mark as incomplete" : "Mark as complete" : null;

  return (
    <Popconfirm
      title={
        isComplete ? "Are you sure to mark this item as incomplete?" : "Are you sure to mark this item as complete?"
      }
      okText="Yes"
      cancelText="Cancel"
      onConfirm={updateItem}
    >
      <Spin spinning={updating}>
        <Button
          danger={isComplete}
          type={"primary"}
          htmlType="button"
          title={isComplete ? "Mark as incomplete" : "Mark as complete"}
          icon={
            isComplete ? <UnlockOutlined /> : <LockOutlined />
          }
        >{text}</Button>
      </Spin>
    </Popconfirm>
  );
};

export default CompleteBtn;
