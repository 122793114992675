import { Prisma, User } from ".prisma/client";
import { Space, Spin, message } from "antd";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Center from "../../components/center";
import DeleteBtn from "../../components/delete-btn";
import EmailButton from "../../components/email-button";
import UserGroupForm from "../../components/user-group-form";
import httpClient from "../../libs/http-client";

type UserGroup = Prisma.UserGroupGetPayload<{
  include: { default_email_template: true, users: true };
}>;

interface UserGroupUpdatePageProps { }

const UserGroupUpdatePage: FunctionComponent<UserGroupUpdatePageProps> = () => {
  const [loading, setLoading] = useState(true);
  const [userGroup, setUserGroup] = useState<UserGroup>();
  const [saving, setSaving] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const onFinish = useCallback(
    async (values: any) => {
      setSaving(true);
      try {
        const response = await httpClient.put<UserGroup>(`/user-groups/${id}`, {
          ...values,
          default_email_template_id: values?.default_email_template?.id ?? null,
        });
        if (response.success && response.data) {
          message.success("User group updated successfully!");
          setUserGroup(response.data);
        } else {
          throw new Error(response.message);
        }
      } catch (error: any) {
        message.error(error.message ?? "Failed to update for unknown reason!");
      }
      setSaving(false);
    },
    [id]
  );

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    httpClient
      .get<UserGroup>(`/user-groups/${id}`)
      .then((response) => {
        if (response.success && response.data) {
          mounted && setUserGroup(response.data);
        } else {
          throw new Error(response.message);
        }
      })
      .catch((error: any) => {
        message.error("User group not found!");
        navigate("/user-groups");
      })
      .finally(() => {
        mounted && setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [id]);

  if (loading) {
    return (
      <Center>
        <Spin />
      </Center>
    );
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <UserGroupForm
        title="Update User Group"
        additionalButtons={[
          <DeleteBtn
            key="delete"
            apiURL={`/user-groups/${id}`}
            onDelete={() => navigate("/user-groups")}
          />,
          <EmailButton
            key={'email'}
            users={userGroup?.users as unknown as User[] ?? []}
            selectedEmailTemplateId={userGroup?.default_email_template_id ?? undefined} />,
        ]}
        onChange={onFinish}
        busy={saving}
        value={userGroup}
      />
    </Space>
  );
};

export default UserGroupUpdatePage;
