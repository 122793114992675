import { ProjectArea } from ".prisma/client";
import { message, Spin } from "antd";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Center from "../../components/center";
import DeleteBtn from "../../components/delete-btn";
import ProjectAreaForm from "../../components/project-area-form";
import httpClient from "../../libs/http-client";

interface ProjectAreaUpdatePageProps { }

const ProjectAreaUpdatePage: FunctionComponent<
  ProjectAreaUpdatePageProps
> = () => {
  const [loading, setLoading] = useState(true);
  const [projectArea, setProjectArea] = useState<
    Partial<ProjectArea> | undefined
  >(undefined);
  const [saving, setSaving] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const onFinish = useCallback(
    async (values: any) => {
      setSaving(true);
      try {
        const response = await httpClient.put<ProjectArea>(
          `/project-areas/${id}`,
          values
        );
        if (response.success && response.data) {
          message.success("Project Area updated successfully!");
          setProjectArea(response.data);
        } else {
          throw new Error(response.message);
        }
      } catch (error: any) {
        message.error(error.message ?? "Failed to update for unknown reason!");
      }
      setSaving(false);
    },
    [id]
  );

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    httpClient
      .get<ProjectArea>(`/project-areas/${id}`)
      .then((response) => {
        if (response.success && response.data) {
          mounted && setProjectArea(response.data);
        } else {
          throw new Error(response.message);
        }
      })
      .catch((error: any) => {
        message.error("ProjectArea not found!");
        navigate("/project-areas");
      })
      .finally(() => {
        mounted && setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [id]);

  if (loading) {
    return (
      <Center>
        <Spin />
      </Center>
    );
  }

  return (
    <ProjectAreaForm
      title="Update Project Area"
      additionalButtons={[
        <DeleteBtn
          key="delete"
          apiURL={`/project-areas/${id}`}
          onDelete={() => navigate("/project-areas")}
        />,
      ]}
      onChange={onFinish}
      busy={saving}
      value={projectArea}
    />
  );
};

export default ProjectAreaUpdatePage;
