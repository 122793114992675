import { message, Switch } from "antd";
import { FunctionComponent, useCallback, useState } from "react";
import httpClient from "../libs/http-client";

interface ToggleSwitchProps {
  isChecked: boolean;
  checkedLabel: string;
  uncheckedLabel: string;
  onChange: (checked: boolean) => void;
  putUrl?: string | ((checked: boolean) => string);
  putBody?: any | ((checked: boolean) => any);
  successMessage?: string | ((checked: boolean) => string);
  errorMessage?: string | ((checked: boolean) => string);
  readonly?: boolean;
}

const ToggleSwitch: FunctionComponent<ToggleSwitchProps> = ({
  isChecked = false,
  checkedLabel = "Yes",
  uncheckedLabel = "No",
  onChange,
  putUrl,
  putBody,
  successMessage = "Saved",
  errorMessage = "Failed",
  readonly = false,
}) => {
  const [changing, setChanging] = useState(false);

  const change = useCallback(async (checked: boolean) => {
    const _url = typeof putUrl === "function" ? putUrl(checked) : putUrl;
    if (!_url) {
      onChange(checked);
      return;
    }

    setChanging(true);
    try {

      const response = await httpClient.put<any>(_url, typeof putBody === "function" ? putBody(checked) : putBody);
      if (response.success && response.data) {
        const _message = typeof successMessage === "function" ? successMessage(checked) : successMessage;
        message.success(_message);
        onChange(checked);
      } else {
        throw new Error(response.message);
      }
    } catch (e: any) {
      const _message = typeof errorMessage === "function" ? errorMessage(checked) : errorMessage;
      message.error(e.message ?? _message);
    }
    setChanging(false);
  }, [putUrl, putBody, successMessage, errorMessage, onChange]);

  return < Switch
    checked={isChecked}
    checkedChildren={checkedLabel}
    unCheckedChildren={uncheckedLabel}
    onChange={change}
    disabled={readonly || changing
    }
    loading={changing}
  />
};

export default ToggleSwitch;
