import ClusterOutlined from "@ant-design/icons/ClusterOutlined";
import HomeOutlined from "@ant-design/icons/HomeOutlined";
import LogoutOutlined from "@ant-design/icons/LogoutOutlined";
import MailOutlined from "@ant-design/icons/MailOutlined";
import MenuFoldOutlined from "@ant-design/icons/MenuFoldOutlined";
import MenuUnfoldOutlined from "@ant-design/icons/MenuUnfoldOutlined";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import PushpinOutlined from "@ant-design/icons/PushpinOutlined";
import SettingOutlined from "@ant-design/icons/SettingOutlined";
import UnorderedListOutlined from "@ant-design/icons/UnorderedListOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import UsergroupAddOutlined from "@ant-design/icons/UsergroupAddOutlined";

import { Grid, Layout, Menu, theme } from "antd";
import { ItemType } from "antd/es/menu/interface";
import {
  FunctionComponent,
  createElement,
  useEffect,
  useMemo,
  useState
} from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Logo from "./components/logo";
import useUser from "./hooks/use-user";

const { useBreakpoint } = Grid;
const { Header, Sider, Content } = Layout;

type Prop = {};

const AppLayout: FunctionComponent<Prop> = ({ }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { user, logout, isSuperAdmin } = useUser();
  const location = useLocation();
  const screens = useBreakpoint();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    if (location.pathname.startsWith("/dashboard")) {
      setCollapsed(true);
    } else if (screens.md) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [screens, location.pathname]);

  const openKey = (() => {
    const path = window.location.pathname;
    if (path.startsWith("/users")) {
      return "/users";
    } else if (path.startsWith("/project-areas")) {
      return "/project-areas";
    } else if (path.startsWith("/projects")) {
      return "/projects";
    } else if (path.startsWith("/user-groups")) {
      return "/user-groups";
    } else if (path.startsWith("/app-configs")) {
      return "/app-configs";
    } else if (path.startsWith("/email-templates")) {
      return "/email-templates";
    } else {
      return undefined;
    }
  })();

  const items: ItemType[] = useMemo(() => {
    const items: ItemType[] = [
      {
        key: "/dashboard",
        label: <Link to="/dashboard">Dashboard</Link>,
        icon: <HomeOutlined />,
      },
    ];
    if (isSuperAdmin) {
      items.push({
        key: "/users",
        label: "Users",
        icon: <UserOutlined />,

        children: [
          {
            key: "/users/list",
            icon: <UnorderedListOutlined />,
            label: <Link to="/users">All Users</Link>,
          },
          {
            key: "/users/create",
            icon: <PlusOutlined />,
            label: <Link to="/users/create">Create User</Link>,
          },
        ],
      },
        {
          key: "/user-groups",
          label: "User Groups",
          icon: <UsergroupAddOutlined />,
          children: [
            {
              key: "/user-groups/list",
              icon: <UnorderedListOutlined />,
              label: <Link to="/user-groups">All User Groups</Link>,
            },
            {
              key: "/user-groups/create",
              icon: <PlusOutlined />,
              label: <Link to="/user-groups/create">Create User Group</Link>,
            }
          ],
        },
        {
          key: "/project-areas",
          label: "Project Areas",
          icon: <ClusterOutlined />,

          children: [
            {
              key: "/project-areas/list",
              icon: <UnorderedListOutlined />,
              label: <Link to="/project-areas">All Project Areas</Link>,
            },
            {
              key: "/project-areas/create",
              icon: <PlusOutlined />,
              label: (
                <Link to="/project-areas/create">Create Project Area</Link>
              ),
            },
          ],
        },
        {
          key: "/app-configs",
          label: "App Configs",
          icon: <SettingOutlined />,
          children: [
            {
              key: "/app-configs/list",
              icon: <UnorderedListOutlined />,
              label: <Link to="/app-configs">All App Configs</Link>,
            }
          ]
        },
        {
          key: "/email-templates",
          label: "Email Templates",
          icon: <MailOutlined />,
          children: [
            {
              key: "/email-templates/list",
              icon: <UnorderedListOutlined />,
              label: <Link to="/email-templates">All Email Templates</Link>,
            },
            {
              key: "/email-templates/create",
              icon: <PlusOutlined />,
              label: (
                <Link to="/email-templates/create">Create Email Template</Link>
              ),
            },
          ],
        }
      );
    }
    const projectItem = {
      key: "/projects",
      label: "Projects",
      icon: <PushpinOutlined />,

      children: [
        {
          key: "/projects/list",
          icon: <UnorderedListOutlined />,
          label: <Link to="/projects">All Projects</Link>,
        },
      ],
    };
    if (user?.admin_of.length) {
      projectItem.children.push({
        key: "/projects/create",
        icon: <PlusOutlined />,
        label: <Link to="/projects/create">Create Project</Link>,
      });
    }
    items.push(projectItem);
    return items;
  }, [user]);

  return (
    <Layout>
      {user && (
        <Sider
          theme="light"
          trigger={null}
          breakpoint="md"
          collapsible
          collapsed={collapsed}
        >
          <Logo />
          <Menu
            mode="inline"
            selectedKeys={[location.pathname]}
            defaultOpenKeys={openKey ? [openKey] : []}
            multiple={false}
            items={items}
          />
          <div className="spacer"></div>
          <Menu
            mode="inline"
            selectedKeys={[location.pathname]}
            items={[
              {
                type: "group",
                key: "/",
                children: [
                  {
                    key: "/profile",
                    icon: <UserOutlined />,
                    label: <Link to="/profile">{user.first_name} {user.last_name}</Link>,
                  },
                  {
                    key: "/sign-out",
                    icon: <LogoutOutlined />,
                    label: "Sign Out",
                    onClick: ({ domEvent }) => {
                      domEvent.preventDefault();
                      logout();
                    },
                  },
                ],
              },
            ]}
          />
        </Sider>
      )}
      <Layout className="site-layout">
        {user && (
          <Header style={{ padding: 0, background: colorBgContainer }}>
            {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            })}
          </Header>
        )}
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
