import { decodeJwt } from "jose";
import AuthTokenPayload from "../../common/auth-token-payload";
import httpClient from "./http-client";

function getToken(key: string) {
    const token = localStorage.getItem(key);
    if (!token) return null;
    const payload = decodeJwt<AuthTokenPayload>(token);
    const exp = payload.exp;
    if (!exp) return null;
    const now = new Date().getTime() / 1000;
    return now >= exp ? null : { token, payload };
}

function saveToken(key: string, token: string) {
    localStorage.setItem(key, token);
}

export function getAccessToken() {
    return getToken("access_token");
}

export function getRefreshToken() {
    return getToken("refresh_token");
}

export function saveAccessToken(token: string) {
    saveToken("access_token", token);
}

export function saveRefreshToken(token: string) {
    saveToken("refresh_token", token);
}

export function removeAccessToken() {
    localStorage.removeItem("access_token");
}

export function removeRefreshToken() {
    localStorage.removeItem("refresh_token");
}

export function clearTokens() {
    removeAccessToken();
    removeRefreshToken();
}

export async function refreshTokens() {
    const refresh_token = getRefreshToken();
    console.log("refresh_token", refresh_token);
    if (!refresh_token) {
        clearTokens();
        return null;
    }
    const response = await httpClient.post<{ access_token: string; refresh_token: string }>(
        "/auth/refresh",
        { refresh_token: refresh_token.token }
    );
    if (response.success) {
        saveAccessToken(response.data?.access_token!);
        saveRefreshToken(response.data?.refresh_token!);
    } else {
        clearTokens();
    }

    return getAccessToken();
}