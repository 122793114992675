import { Project } from ".prisma/client";
import { message } from "antd";
import { FunctionComponent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProjectForm from "../../components/project-form";
import useUser from "../../hooks/use-user";
import httpClient from "../../libs/http-client";

interface ProjectCreatePageProps { }

const ProjectCreatePage: FunctionComponent<ProjectCreatePageProps> = () => {
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const { adminOf } = useUser();
  const onFinish = useCallback(async (values: Partial<Project>) => {
    setSaving(true);
    try {
      if (!adminOf(values.area_id)) {
        throw new Error("You are not authorized to create project in this area!");
      }
      const response = await httpClient.post<Project>("/projects", values);
      if (response.success && response.data) {
        message.success("Project created successfully!");
        navigate(`/projects/${response.data.id}`);
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      message.error(error.message ?? "Failed to create for unknown reason!");
    }
    setSaving(false);
  }, [adminOf]);
  return (
    <ProjectForm
      title="Create Project"
      additionalButtons={[]}
      onChange={onFinish}
      busy={saving}
    />
  );
};

export default ProjectCreatePage;
